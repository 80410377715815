import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function YimbyLawLogo() {
  return (<StaticImage
      src="../images/yimby-law-text-right-dark-gray-lg.png"
      alt="Yimby Law Logo"
      placeholder="blurred"
      width={120}
      className=""
      transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}
