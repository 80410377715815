import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Home3Logo() {
  return (<StaticImage
      src="../images/Home3-grayscale-logo.svg"
      alt="Home3 Logo"
      placeholder="blurred"
      width={150}
      className=""
      // transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}