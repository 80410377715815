import React from 'react';
import { Home3Logo } from '../../components/Home3Logo';
import { PHNLogo } from '../../components/PHNLogo';
import { YimbyLawLogo } from '../../components/YimbyLawLogo';

import { AnchorLink } from "gatsby-plugin-anchor-links";

function RightArrow() {
	return (
		<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path d='M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z' fill='white' />
		</svg>
	);
}

function HeaderAnnouncement() {
	return (
		<div className=' '>
			<AnchorLink to={'/#participate'} stripHash>
				<button className='bg-light_black px-4 h-[66px] rounded-full '>
					<div className='flex items-center gap-4'>
						<div className='badge bg-white text-neutralgray-800 font-bold py-[18px] px-[18px] text-[12px]'>NOTICE</div>

						<span className='text-[16px] dark leading-5'>Submissions are due by January 29!</span>
						<RightArrow />
					</div>
				</button>
			</AnchorLink>
		</div>
	);

}

export function Header() {
	return (

		<header
			className='md:sticky top-0 z-40 py-2 flex-none bg-white md:bg-white/90 90 md:backdrop-blur-sm'
			id='header'
		>
			<div className='container'>
					<div className='flex flex-col md:flex-row justify-between w-full gap-8'>
						{/*<HeaderAnnouncement />*/}
            <div></div>
						<div className='flex justify-between items-center gap-10'>
							<a className='' href='https://home3.co' target="_blank">
								<Home3Logo />
							</a>
							<a className='' href='https://naphnetwork.org' target="_blank">
								<PHNLogo />
							</a>
							<a className='' href='https://yimbylaw.org' target="_blank">
								<YimbyLawLogo />
							</a>
						</div>
					</div>
			</div>
		</header>
	);
}
