import PageLayout from './PageLayout';
import React from "react";

export default function Layout({ children }: { children: React.ReactNode }) {

  return (
    <PageLayout>
      <section className="px-6 sm:px-6 py-12 sm:py-16 lg:py-20 mx-auto max-w-3xl">
        <header>
          <h1
            className="text-center text-4xl md:text-5xl font-bold leading-tighter tracking-tighter mb-8 md:mb-16 font-heading"
          >
            {/*{frontmatter.title}*/}
          </h1>
        </header>
        <article className="prose dark:prose-invert">
          {children}
        </article>
      </section>
    </PageLayout>
  )
}
