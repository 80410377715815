// import BaseLayout from './BaseLayout';

import React from "react";
import { Header } from "../sections/homepage/header";
import { Toaster } from "react-hot-toast";

export default function Layout({ children }: { children: React.ReactNode }) {

  return (
    <div>
      <Header></Header>
      {children}
    </div>
  )
};

// <BaseLayout >
// 	<Announcement />
// 	<Header />
// 	<main>
//
// 				<slot />
// 	</main>
// 	<Footer />
// </BaseLayout>

