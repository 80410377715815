import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function PHNLogo() {
  return (<StaticImage
      src="../images/PHN-grayscale-logo-2000.png"
      alt="Passive House Network Logo"
      placeholder="blurred"
      width={170}
      className=""
      transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}
